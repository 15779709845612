import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { type SurveyStoplightQuestion } from '~/utils/types/current-survey'
import { type StoplightColorValue } from '~/utils/types/surveys'

import IndicatorBall from './IndicatorBall'

function indicatorColorByAnswer(value: number) {
  if (value === 3) return 'green'
  if (value === 2) return 'yellow'
  if (value === 1) return 'red'
  if (value === 0) return 'skipped'
  if (value === 9) return 'doesNotApply'
  throw new Error('Invalid value')
}

interface AllSurveyIndicatorsProps {
  priorities: Array<{ indicator: string }>
  achievements: Array<{ indicator: string }>
  indicators: Array<{ key: string; value: StoplightColorValue }>
  surveyStoplightQuestions: SurveyStoplightQuestion[]
}
export default function AllSurveyIndicators({
  indicators,
  priorities,
  achievements,
  surveyStoplightQuestions,
}: AllSurveyIndicatorsProps) {
  const { t } = useTranslation()

  const enrichedIndicators = useMemo(() => {
    return indicators.map(indicator => {
      const question = surveyStoplightQuestions.find(
        q => q.codeName === indicator.key,
      )
      return {
        ...indicator,
        questionText:
          question?.questionText ?? t('views.lifemap.valueNotAvailable'),
      }
    })
  }, [surveyStoplightQuestions, indicators, t])

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        margin: 2,
      }}
    >
      {enrichedIndicators ? (
        enrichedIndicators.map(indicator => {
          const color = indicatorColorByAnswer(indicator.value)
          return (
            <Box
              key={indicator.key}
              sx={{
                margin: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <IndicatorBall
                color={color}
                variant="small"
                animated={false}
                tooltipText={indicator.questionText}
                priority={priorities.some(
                  prior => prior.indicator === indicator.key,
                )}
                achievement={achievements.some(
                  prior => prior.indicator === indicator.key,
                )}
              />
            </Box>
          )
        })
      ) : (
        <Box></Box>
      )}
    </Box>
  )
}
