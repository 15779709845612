import { getHomePagePerPermissions } from './permissions-utils'
import { type Environment, type Role, type User } from './types/user'

export function getEnvironment(): Environment {
  const backendEnvironment = import.meta.env.VITE_API_URL

  if (backendEnvironment.includes('platform')) return 'platform'
  if (backendEnvironment.includes('demo')) return 'demo'
  return 'testing'
}

export const getPlatform = (env: Environment) =>
  `https://${env}.povertystoplight.org`

export const NEW = 'new'
// Used to mark access to a new view
export const ACCESS = 'access'

// IMPORTANT: Add new roles by their hierarchy otherwise getRolesForFilter won't work properly
export const ROLES = {
  ADMIN: 'ROLE_ROOT',
  PS_TEAM: 'ROLE_PS_TEAM',
  HUB_ADMIN: 'ROLE_HUB_ADMIN',
  STAKEHOLDER: 'ROLE_STAKEHOLDER',
  ORGANIZATION_ADMIN: 'ROLE_APP_ADMIN',
  FACILITATOR_ADMIN: 'ROLE_SURVEY_USER_ADMIN',
  FACILITATOR: 'ROLE_SURVEY_USER',
  SURVEY_TAKER: 'ROLE_SURVEY_TAKER',
  FAMILY: 'ROLE_FAMILY_USER',
} as const

export const ROLES_MAP: Record<
  (typeof ROLES)[keyof typeof ROLES],
  Array<{ item: string; platform: string; options?: string[] }>
> = {
  [ROLES.ADMIN]: [
    { item: 'dashboard', platform: ACCESS },
    { item: 'reports', platform: NEW },
    { item: 'allies', platform: NEW, options: ['hubs', 'stakeholders'] },
    { item: 'organizations', platform: ACCESS },
    { item: 'projects', platform: ACCESS },
    { item: 'surveysList', platform: NEW },
    { item: 'users', platform: NEW },
    { item: 'families', platform: NEW },
    { item: 'detail', platform: ACCESS },
    { item: 'map', platform: NEW },
    { item: 'solutions', platform: ACCESS },
    { item: 'editFamilyImages', platform: ACCESS },
    { item: 'offline-maps', platform: ACCESS },
    { item: 'support', platform: ACCESS },
    { item: 'survey-builder', platform: ACCESS },
    {
      item: 'libraries',
      platform: NEW,
      options: ['dimensions', 'indicators', 'economics'],
    },
  ],
  [ROLES.STAKEHOLDER]: [],
  [ROLES.HUB_ADMIN]: [
    { item: 'dashboard', platform: ACCESS },
    { item: 'reports', platform: NEW },
    { item: 'projects', platform: ACCESS },
    { item: 'surveysList', platform: NEW },
    { item: 'organizations', platform: NEW },
    { item: 'organizationEdit', platform: ACCESS },
    { item: 'users', platform: NEW },
    { item: 'families', platform: NEW },
    { item: 'detail', platform: ACCESS },
    { item: 'map', platform: NEW },
    { item: 'solutions', platform: ACCESS },
    { item: 'offline-maps', platform: ACCESS },
    { item: 'support', platform: ACCESS },
    { item: 'interventions', platform: NEW },
    { item: 'survey-builder', platform: ACCESS },
  ],
  [ROLES.PS_TEAM]: [
    { item: 'dashboard', platform: ACCESS },
    { item: 'reports', platform: NEW },
    { item: 'allies', platform: NEW, options: ['hubs', 'stakeholders'] },
    { item: 'users', platform: NEW },
    { item: 'surveysList', platform: NEW },
    { item: 'map', platform: NEW },
    { item: 'organizations', platform: ACCESS },
    { item: 'projects', platform: ACCESS },
    { item: 'solutions', platform: ACCESS },
    { item: 'editFamilyImages', platform: ACCESS },
    { item: 'offline-maps', platform: ACCESS },
    { item: 'support', platform: ACCESS },
    { item: 'survey-builder', platform: ACCESS },
    {
      item: 'libraries',
      platform: NEW,
      options: ['dimensions', 'indicators', 'economics'],
    },
  ],
  [ROLES.ORGANIZATION_ADMIN]: [
    { item: 'dashboard', platform: ACCESS },
    { item: 'reports', platform: NEW },
    { item: 'projects', platform: NEW },
    { item: 'surveysList', platform: NEW },
    { item: 'users', platform: NEW },
    { item: 'families', platform: NEW },
    { item: 'detail', platform: ACCESS },
    { item: 'map', platform: NEW },
    { item: 'solutions', platform: ACCESS },
    { item: 'editFamily', platform: ACCESS },
    { item: 'editFamilyImages', platform: ACCESS },
    { item: 'support', platform: ACCESS },
    { item: 'survey-builder', platform: ACCESS },
    { item: 'massive-interventions', platform: NEW },
  ],
  [ROLES.FACILITATOR_ADMIN]: [
    { item: 'reports', platform: NEW },
    { item: 'surveys', platform: NEW },
    { item: 'dashboard', platform: ACCESS },
    { item: 'families', platform: NEW },
    { item: 'priorities', platform: ACCESS },
    { item: 'users', platform: NEW },
    { item: 'detail', platform: ACCESS },
    { item: 'map', platform: NEW },
    { item: 'editFamily', platform: ACCESS },
    { item: 'editFamilyImages', platform: ACCESS },
    { item: 'solutions', platform: ACCESS },
    { item: 'support', platform: ACCESS },
  ],
  [ROLES.FACILITATOR]: [
    { item: 'surveys', platform: NEW },
    { item: 'dashboard', platform: ACCESS },
    { item: 'families', platform: NEW },
    { item: 'priorities', platform: ACCESS },
    { item: 'detail', platform: ACCESS },
    { item: 'map', platform: NEW },
    { item: 'editFamily', platform: ACCESS },
    { item: 'editFamilyImages', platform: ACCESS },
    { item: 'solutions', platform: ACCESS },
    { item: 'support', platform: ACCESS },
  ],
  [ROLES.SURVEY_TAKER]: [
    { item: 'surveys', platform: NEW },
    { item: 'support', platform: ACCESS },
  ],
  [ROLES.FAMILY]: [
    { item: 'my-profile', platform: NEW },
    { item: 'detail', platform: ACCESS },
    { item: 'priorities', platform: ACCESS },
    { item: 'achievements', platform: ACCESS },
    { item: 'support', platform: ACCESS },
    { item: 'solutions', platform: ACCESS },
  ],
}

/**
 * Check if a user's role is allowed to a certain module
 */
export function checkAccess(user: User | null, item: string) {
  if (!user) return false
  return (
    !!user.role &&
    !!ROLES_MAP[user.role] &&
    !!ROLES_MAP[user.role].find(
      r => r.item === item || r.options?.find(o => o === item),
    )
  )
}

export function checkAccessToSolution(user: User) {
  const hasPermission =
    (!user.permissions && checkAccess(user, 'solutions')) ||
    (user.permissions && !!user.permissions.some(p => p === 'solutions_read'))

  if (
    hasPermission &&
    !user.hub &&
    !!user.organization &&
    !user.organization.id
  )
    return true
  else if (
    hasPermission &&
    !!user.hub &&
    user.hub.allowSolutions &&
    !!user.organization &&
    !user.organization.id
  )
    return true
  else if (
    hasPermission &&
    user?.organization?.application?.allowSolutions &&
    user?.organization?.solutionsAccess !== 'NONE'
  ) {
    if (user.role === ROLES.ORGANIZATION_ADMIN) {
      return true
    }

    if (user.role === ROLES.FAMILY) {
      return true
    }

    if (
      (user.role === ROLES.FACILITATOR_ADMIN ||
        user.role === ROLES.FACILITATOR) &&
      user.organization?.solutionsAllowedFacilitators
    ) {
      return true
    }
  }

  return false
}

export function canAccess(
  user: User | null,
  item: string, // stakeholder
  typePerm?: string,
) {
  let granted = false

  if (!user) return granted

  if (Array.isArray(user.permissions) && item) {
    const permission = typePerm ? `${item}_${typePerm}` : `${item}_read`
    granted = user.permissions.some(p => p === permission)
  }

  if (!Array.isArray(user.permissions)) {
    granted =
      !!user.role &&
      !!ROLES_MAP[user.role].find(
        r => r.item === item || r.options?.find(o => o === item),
      )
  }

  if (item === 'solutions') {
    granted = granted && checkAccessToSolution(user)
  }

  if (item === 'projects') {
    // TODO:  Avoid special condition por surveys users
    granted =
      (granted && checkAccessToProjects(user)) ||
      (!user.permissions &&
        (user.role === ROLES.FACILITATOR ||
          user.role === ROLES.FACILITATOR_ADMIN))
  }
  return granted
}

export function getHomePage(user: User): string {
  if (user.permissions) {
    return getHomePagePerPermissions(user.permissions)
  }
  return ROLES_MAP[user.role][0].item
}

export function checkAccessToProjects(user: User) {
  const hasPermission =
    (!user.permissions && checkAccess(user, 'projects')) ||
    (user.permissions &&
      !!user.permissions.some(
        p => p === 'projects_read' || p === 'projects_view',
      ))

  if (
    hasPermission &&
    !user.hub &&
    !!user.organization &&
    !user.organization.id &&
    !user.stakeholder
  )
    return true
  else if (
    hasPermission &&
    !!user.hub &&
    user.hub.projectsSupport &&
    !!user.organization &&
    !user.organization.id
  )
    return true
  else if (
    hasPermission &&
    !!user.organization &&
    !!user.organization.projectsAccess &&
    ((!!user.organization.application &&
      !!user.organization.application.projectsSupport) ||
      (!!user.hub && user.hub.projectsSupport))
  ) {
    return true
  }

  return false
}

export function checkAccessToFamilyUsers(user: User) {
  if (!user.role) return false
  else if (user.role === ROLES.ADMIN || user.role === ROLES.PS_TEAM) return true
  else if (
    !!user.hub &&
    Array.isArray(user.hub.labels) &&
    user.hub.labels.includes('allowFamilyUsers')
  )
    return true
  return false
}

export function checkAccessToInterventions(user: User | null) {
  if (!user) return false
  else if (user.role === ROLES.ADMIN || user.role === ROLES.PS_TEAM) return true
  else if (
    !!user.hub &&
    Array.isArray(user.hub.labels) &&
    user.hub.labels.includes('allowInterventions')
  )
    return true
  return false
}

export function getVisibleRoles(user: User, shouldReturnAllRoles = false) {
  const roles: Role[] = []
  if (user.role === ROLES.ADMIN) {
    roles.push(ROLES.HUB_ADMIN)
    roles.push(ROLES.PS_TEAM)
    roles.push(ROLES.STAKEHOLDER)
  } else if (user.role === ROLES.PS_TEAM) {
    roles.push(ROLES.HUB_ADMIN)
    roles.push(ROLES.STAKEHOLDER)
  } else if (user.role === ROLES.HUB_ADMIN) {
    roles.push(ROLES.ORGANIZATION_ADMIN)
  } else if (user.role === ROLES.ORGANIZATION_ADMIN) {
    roles.push(ROLES.FACILITATOR_ADMIN)
    roles.push(ROLES.FACILITATOR)
    roles.push(ROLES.SURVEY_TAKER)
    //* NOTE: This will be removed until the Family User is used again
    //* Check: https://povertystoplight.atlassian.net/browse/PSP-1846
    // if (checkAccessToFamilyUsers(user)) roles.push(ROLES.FAMILY)
  } else if (user.role === ROLES.FACILITATOR_ADMIN) {
    roles.push(ROLES.FACILITATOR)
    roles.push(ROLES.SURVEY_TAKER)
  }

  const allRoles = Object.keys(ROLES) as Role[]
  return shouldReturnAllRoles ? allRoles : roles
}

export function getRolesForFilter(user: User) {
  let roles = Object.keys(ROLES).filter(r => r !== ROLES.FAMILY)
  roles = roles.slice(roles.findIndex(r => r === user.role) + 1, roles.length)
  checkAccessToFamilyUsers(user)
  return roles
}
