import { zodResolver } from '@hookform/resolvers/zod'
import {
  Cancel,
  CloudDownload,
  Home,
  Mail,
  Print,
  QrCode2Rounded,
  Send,
  WhatsApp,
} from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Alert,
  Button,
  CardMedia,
  Container,
  Modal,
  Stack,
  Typography,
} from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { PhoneNumberUtil } from 'google-libphonenumber'
import { useSnackbar } from 'notistack'
import { useForm } from 'react-hook-form'
import { TextFieldElement } from 'react-hook-form-mui'
import { useLocation, useNavigate } from 'react-router-dom'
import { useLocalStorage } from 'usehooks-ts'
import { z } from 'zod'

import { downloadPdf, sendLifemapPdfv2, sendWhatsappMessage } from '~/api'
import checkgif from '~/assets/check.gif'
import LeaveModal from '~/components/LeaveModal'
import TitleBar from '~/components/TitleBar'
import PhoneNumberInput from '~/components/inputs/PhoneNumberInput'
import QrCodeModal from '~/components/modals/QrCodeModal'
import AllSurveyIndicators from '~/components/summary/AllSurveyIndicators'
import { useAppSelector } from '~/redux/hooks'
import { callingCodes } from '~/utils/calling-codes'
// import { getFormattedPhoneNumber } from '~/utils/form-utils'
import { useRole } from '~/utils/hooks/useRole'
import { ROLES } from '~/utils/role-utils'
import { type PlatformLanguage } from '~/utils/types/i18n'
import { excludeFalsyWithMessage } from '~/utils/zod'

const phoneUtil = PhoneNumberUtil.getInstance()

export default function SendLifemap() {
  const navigate = useNavigate()

  const { state } = useLocation()

  const { enqueueSnackbar } = useSnackbar()
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const user = useAppSelector(state => {
    if (!state.user) throw new Error('No user')
    return state.user
  })

  const currentDraft = useAppSelector(state => {
    if (!state.currentDraft) throw new Error('No currentDraft')
    return state.currentDraft
  })

  const currentSurvey = useAppSelector(state => {
    if (!state.currentSurvey) throw new Error('No currentSurvey')
    return state.currentSurvey
  })

  const [isPdfLoading, setIsPdfLoading] = useState(false)

  const [isQrModalOpen, setIsQrModalOpen] = useState(false)

  const [contactModalData, setContactModalData] = useState<{
    isOpen: boolean
    mode: 'EMAIL' | 'WHATSAPP'
  }>({
    isOpen: false,
    mode: 'EMAIL',
  })

  const [modalData, setModalData] = useState({
    modalTitle: '',
    openModal: false,
    modalVariant: '',
    modalSubtitle: '',
    modalContinueButtonText: '',
  })

  const { userHasAnyRole } = useRole()

  const canUserOpenQR = useMemo(() => {
    const canShowQR = currentSurvey.surveyConfig.familyCodeQRSupport

    const hasUserRoles = userHasAnyRole([
      ROLES.FAMILY,
      ROLES.FACILITATOR,
      ROLES.SURVEY_TAKER,
      ROLES.FACILITATOR_ADMIN,
    ])

    return hasUserRoles && canShowQR
  }, [userHasAnyRole, currentSurvey])

  const familyCode: string | null = state?.familyCode
  const familyName: string | null = state?.familyName

  const primaryParticipant =
    currentDraft.familyData.familyMembersList.find(
      member => member.firstParticipant,
    ) ?? null

  const [userCreationMode] = useLocalStorage<'PHONE' | 'EMAIL' | null>(
    'user-creation-mode',
    null,
  )

  const whatsAppMutation = useMutation({
    mutationFn: async ({ snapshotId }: { snapshotId: number }) => {
      return await sendWhatsappMessage(snapshotId)
    },
    onSuccess: () => {
      enqueueSnackbar(t('views.final.whatsappSent'), {
        variant: 'success',
      })
    },
    onError: () => {
      enqueueSnackbar(t('general.error'), {
        variant: 'error',
        autoHideDuration: 5000,
      })
    },
  })

  const canShowWhatsappButton =
    (currentSurvey.surveyConfig.isSurveyAnonymous &&
      userCreationMode !== null &&
      userCreationMode === 'PHONE') ||
    !!primaryParticipant?.phoneNumber

  function handleContinue(
    familyId: number | undefined,
    isRetake = false,
    justStoplight: boolean,
  ) {
    if (user.role === ROLES.FAMILY) {
      navigate(`/my-profile`)
      return
    }

    if (isRetake || justStoplight) {
      if (familyId !== 0 && !familyId) {
        enqueueSnackbar(t('general.error'), { variant: 'error' })
        console.error('PSP Platform: ', `familyID is`, familyId)
        return
      }

      navigate(`/family/${familyId}`)
      return
    }

    if (user.dynamicLink) {
      navigate(`/lifemap/farewell`)
      return
    }

    navigate(`/surveys`)
  }

  async function handleFileAction(shouldDownload: boolean) {
    const id = currentDraft.snapshotId
    if (!id) return

    setIsPdfLoading(true)

    try {
      const { data } = await downloadPdf(id, language as PlatformLanguage)

      const blob = new Blob([data], { type: 'application/pdf' })
      const url = window.URL.createObjectURL(blob)

      const tempLink = document.createElement('a')
      tempLink.href = url
      if (shouldDownload) {
        tempLink.setAttribute('download', 'Lifemap.pdf')
        tempLink.click()
      } else {
        tempLink.target = '_blank'
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
      }
    } catch (err) {
      console.error('PSP Platform:', err)
      setModalData({
        modalTitle: t('general.warning'),
        modalSubtitle: t('views.final.downloadError'),
        modalContinueButtonText: t('general.gotIt'),
        modalVariant: 'warning',
        openModal: true,
      })
    } finally {
      setIsPdfLoading(false)
    }
  }

  return (
    <>
      {familyName && familyCode && (
        <QrCodeModal
          title={familyName}
          qrCodeValue={familyCode}
          isModalOpen={isQrModalOpen}
          setIsModalOpen={setIsQrModalOpen}
        />
      )}

      {currentDraft.snapshotId && (
        <EmailConfirmationModal
          mode={contactModalData.mode}
          isOpen={contactModalData.isOpen}
          snapshotID={currentDraft.snapshotId}
          defaultEmail={primaryParticipant?.email ?? ''}
          defaultWhatsApp={primaryParticipant?.phoneNumber ?? ''}
          isSurveyAnonymous={currentSurvey.surveyConfig.isSurveyAnonymous}
          setIsOpen={isOpen => {
            setContactModalData({
              ...contactModalData,
              isOpen,
            })
          }}
        />
      )}

      <LeaveModal
        singleAction
        onClose={() => {}}
        open={modalData.openModal}
        title={modalData.modalTitle}
        variant={modalData.modalVariant}
        subtitle={modalData.modalSubtitle}
        continueButtonText={modalData.modalContinueButtonText}
        leaveAction={e => {
          if (e) setModalData({ ...modalData, openModal: false })
        }}
      />

      <TitleBar title={t('views.send.title')} showButton={false} />
      <Container maxWidth="lg">
        <Stack justifyContent="center" alignItems="center" gap={1}>
          <CardMedia
            src={checkgif}
            component="img"
            sx={{ borderRadius: 5, maxWidth: 250 }}
          />

          <Typography variant="h5" textAlign="center">
            {t('views.send.subtitle')}
          </Typography>
          <Typography variant="h6" textAlign="center">
            {t('views.send.chooseOption')}
          </Typography>
        </Stack>

        <AllSurveyIndicators
          priorities={currentDraft.priorities}
          achievements={currentDraft.achievements}
          indicators={currentDraft.indicatorSurveyDataList}
          surveyStoplightQuestions={currentSurvey.surveyStoplightQuestions}
        />

        <Stack
          my={4}
          gap={2}
          direction="row"
          flexWrap="wrap"
          justifyContent="space-around"
        >
          {canUserOpenQR && (
            <Button
              color="primary"
              variant="outlined"
              startIcon={<QrCode2Rounded />}
              onClick={() => {
                setIsQrModalOpen(true)
              }}
            >
              {t('views.familyProfile.familyQr')}
            </Button>
          )}

          <Button
            color="primary"
            variant="outlined"
            startIcon={<Mail />}
            onClick={() => {
              setContactModalData({ mode: 'EMAIL', isOpen: true })
            }}
          >
            {t('views.final.email')}
          </Button>

          {canShowWhatsappButton && (
            <LoadingButton
              loading={whatsAppMutation.isLoading}
              variant="outlined"
              color="primary"
              startIcon={<WhatsApp />}
              onClick={async () => {
                // NOTE: This is a momentary fix due to the backend not supporting receiving a phone number for whatsapp messages
                // setContactModalData({ mode: 'WHATSAPP', isOpen: true })

                if (!currentDraft.snapshotId) return
                whatsAppMutation.mutate({ snapshotId: currentDraft.snapshotId })
              }}
            >
              {t('views.final.whatsapp')}
            </LoadingButton>
          )}

          <Button
            color="primary"
            variant="outlined"
            startIcon={<Print />}
            disabled={isPdfLoading}
            onClick={async () => {
              await handleFileAction(false)
            }}
          >
            {t('views.final.print')}
          </Button>
          <Button
            variant="outlined"
            color="primary"
            disabled={isPdfLoading}
            startIcon={<CloudDownload />}
            onClick={async () => {
              await handleFileAction(true)
            }}
          >
            {t('views.final.download')}
          </Button>
        </Stack>

        <Stack direction="row" justifyContent="center">
          <LoadingButton
            color="primary"
            variant="contained"
            startIcon={<Home />}
            onClick={() => {
              handleContinue(
                currentDraft.familyData.familyId,
                currentDraft.isRetake,
                currentDraft.justStoplight,
              )
            }}
          >
            {t('views.send.goTo')}
          </LoadingButton>
        </Stack>
      </Container>
    </>
  )
}

interface ContactModalProps {
  isOpen: boolean
  snapshotID: number
  mode: 'EMAIL' | 'WHATSAPP'
  isSurveyAnonymous: boolean
  defaultEmail: string
  defaultWhatsApp: string
  setIsOpen: (isOpen: boolean) => void
}

function EmailConfirmationModal({
  mode,
  isOpen,
  snapshotID,
  defaultEmail,
  defaultWhatsApp,
  isSurveyAnonymous,
  setIsOpen,
}: ContactModalProps) {
  const { enqueueSnackbar } = useSnackbar()
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const currentDraft = useAppSelector(state => {
    if (!state.currentDraft) throw new Error('No currentDraft')
    return state.currentDraft
  })

  const defaultSchema = z.object({
    _mode: z.union([z.literal('EMAIL'), z.literal('WHATSAPP')]),
  })

  const emailSchema = z.object({
    _mode: z.literal('EMAIL'),
    email: z
      .string({ required_error: t('validation.fieldIsRequired') })
      .min(1, { message: t('validation.fieldIsRequired') })
      .email({ message: t('validation.validEmailAddress') }),
  })

  const whatsAppSchema = z.object({
    _mode: z.literal('WHATSAPP'),
    phoneCode: z
      .object({
        code: z.string(),
        id: z.string(),
        label: z.string(),
      })
      .nullable()
      .transform(excludeFalsyWithMessage(t('validation.fieldIsRequired'))),
    phoneNumber: z
      .string()
      .min(1, t('validation.fieldIsRequired'))
      .nullable()
      .transform(excludeFalsyWithMessage(t('validation.fieldIsRequired'))),
  })

  const modeSchemas = z.discriminatedUnion('_mode', [
    emailSchema,
    whatsAppSchema,
  ])

  const validationSchema = z
    .intersection(modeSchemas, defaultSchema)
    .superRefine((values, ctx) => {
      if (values._mode === 'WHATSAPP') {
        const { phoneCode, phoneNumber } = values

        if (!phoneCode) {
          ctx.addIssue({
            code: 'custom',
            message: t('validation.validPhoneNumber'),
            path: ['phoneCode'],
          })
        }

        try {
          const international = `+${phoneCode.id} ${phoneNumber}`
          const phone = phoneUtil.parse(international, phoneCode.code)
          const isValid = phoneUtil.isValidNumberForRegion(
            phone,
            phoneCode.code,
          )

          if (!isValid) throw new Error('Invalid Number')
        } catch {
          ctx.addIssue({
            code: 'custom',
            message: t('validation.validPhoneNumber'),
            path: ['phoneNumber'],
          })
        }
      }
    })
  type FormSchemaInput = z.infer<typeof validationSchema>

  const {
    reset,
    handleSubmit,
    control,
    setValue,
    formState: { isSubmitting },
  } = useForm<FormSchemaInput>({
    resolver: zodResolver(validationSchema),
    defaultValues: { _mode: mode },
  })

  const phoneNumber = useMemo(() => {
    const primaryParticipant = currentDraft.familyData.familyMembersList.find(
      member => member.firstParticipant,
    )

    if (!primaryParticipant) return null

    const fullPhoneCode = callingCodes.find(
      callingCode => callingCode.value === primaryParticipant.phoneCode,
    )

    if (!fullPhoneCode) return null

    return {
      number: primaryParticipant.phoneNumber,
      code: {
        code: fullPhoneCode.code,
        id: fullPhoneCode.value,
        label: fullPhoneCode.country,
      },
    }
  }, [currentDraft])

  useEffect(() => {
    if (mode === 'WHATSAPP') {
      if (phoneNumber) {
        reset({
          phoneCode: phoneNumber.code,
          phoneNumber: phoneNumber.number ?? '',
        })
      }
    }

    if (mode === 'EMAIL') {
      reset({
        email: defaultEmail,
      })
    }

    setValue('_mode', mode)
  }, [defaultEmail, mode, phoneNumber, reset, setValue])

  const platformLanguage = language as PlatformLanguage

  const emailMutation = useMutation({
    mutationFn: async (email: string) => {
      return await sendLifemapPdfv2(snapshotID, platformLanguage, email)
    },
    onSuccess: () => {
      enqueueSnackbar(t('views.final.emailSent'), {
        variant: 'success',
        autoHideDuration: 5000,
      })
    },
    onError: () => {
      enqueueSnackbar(t('general.error'), {
        variant: 'error',
        autoHideDuration: 5000,
      })
    },
    onSettled: () => {
      setIsOpen(false)
    },
  })

  // const whatsAppMutation = useMutation({
  //   mutationFn: async ({
  //     snapshotId,
  //     whatsappNumber,
  //   }: {
  //     snapshotId: number
  //     whatsappNumber: string
  //   }) => {
  //     return await sendWhatsappMessage(snapshotId, whatsappNumber)
  //   },
  //   onSuccess: () => {
  //     enqueueSnackbar(t('views.final.whatsappSent'), {
  //       variant: 'success',
  //     })
  //   },
  //   onError: () => {
  //     enqueueSnackbar(t('general.error'), {
  //       variant: 'error',
  //       autoHideDuration: 5000,
  //     })
  //   },
  //   onSettled: () => {
  //     setIsOpen(false)
  //   },
  // })

  return (
    <Modal
      open={isOpen}
      disableAutoFocus
      disableEnforceFocus
      onClose={() => {
        if (isSubmitting) return
        setIsOpen(false)
      }}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Stack
        gap={2}
        component="form"
        noValidate
        onSubmit={handleSubmit(async values => {
          if (values._mode === 'EMAIL') {
            await emailMutation.mutateAsync(values.email)
          }
          // if (values._mode === 'WHATSAPP') {
          //   if (!currentDraft.snapshotId) return

          //   const whatsappNumber = getFormattedPhoneNumber({
          //     phoneCode: values.phoneCode,
          //     phoneNumber: values.phoneNumber,
          //   })

          //   await whatsAppMutation.mutateAsync({
          //     snapshotId: currentDraft.snapshotId,
          //     whatsappNumber,
          //   })
          // }
        })}
        sx={{
          p: 2,
          borderRadius: 5,
          width: { xs: '95%', md: '50%' },
          bgcolor: theme => theme.palette.background.default,
          alignItems: 'center',
        }}
      >
        <Typography variant="h5" textAlign="center">
          {mode === 'EMAIL'
            ? t('views.final.confirmYourMail')
            : t('views.final.confirmYourWhatsapp')}
        </Typography>

        {isSurveyAnonymous && (
          <Alert severity="info" sx={{ mt: theme => theme.spacing(2) }}>
            {t('views.final.contactMethodInfo')}
          </Alert>
        )}

        {mode === 'EMAIL' && (
          <TextFieldElement
            required
            fullWidth
            type="email"
            name="email"
            control={control}
            disabled={isSubmitting}
            label={t('views.final.email')}
          />
        )}

        {mode === 'WHATSAPP' && (
          <PhoneNumberInput
            autocompleteProps={{
              required: true,
              label: t('views.family.phoneCode'),
              control,
              name: 'phoneCode',
              autocompleteProps: {
                autoHighlight: true,
              },
            }}
            textFieldProps={{
              required: true,
              label: t('views.family.phone'),
              control,
              name: 'phoneNumber',
            }}
          />
        )}

        <Stack direction="row" justifyContent="space-around" gap={2}>
          <Button
            variant="outlined"
            startIcon={<Cancel />}
            disabled={isSubmitting}
            onClick={() => {
              setIsOpen(false)
            }}
          >
            {t('general.cancel')}
          </Button>
          <LoadingButton
            type="submit"
            color="primary"
            variant="contained"
            startIcon={<Send />}
            loading={isSubmitting}
          >
            {t('general.send')}
          </LoadingButton>
        </Stack>
      </Stack>
    </Modal>
  )
}
