import { type CSSProperties } from 'react'

import { Box, Tooltip } from '@mui/material'
import { Spring } from 'react-spring/renderprops'

import iconAchievement from '~/assets/icon_achievement.png'
import iconPriority from '~/assets/icon_priority.png'
import { COLORS } from '~/theme'

const colorSpehereDiameterVariants: Record<SphereVariant, number> = {
  tiny: 12,
  small: 26,
  medium: 42,
  normal: 60,
}

type SphereVariant = 'tiny' | 'small' | 'normal' | 'medium'
type SphereColor = 'red' | 'yellow' | 'green' | 'skipped' | 'doesNotApply'

interface IndicatorBallProps {
  variant?: SphereVariant
  color: SphereColor
  animated?: boolean
  priority?: boolean
  achievement: boolean
  displayTooltip?: boolean
  styles?: CSSProperties
  tooltipText?: string
}

export default function IndicatorBall({
  color,
  styles = {},
  animated = true,
  priority = false,
  variant = 'normal',
  achievement = false,
  displayTooltip = false,
  tooltipText,
}: IndicatorBallProps) {
  const { t } = useTranslation()
  const diameter = colorSpehereDiameterVariants[variant]

  const spherePropertiesByColor = {
    red: { color: COLORS.RED, label: t('views.lifemap.red') },
    green: { color: COLORS.GREEN, label: t('views.lifemap.green') },
    yellow: { color: COLORS.YELLOW, label: t('views.lifemap.yellow') },
    skipped: { color: COLORS.SKIPPED_GREY, label: t('views.lifemap.skipped') },
    doesNotApply: {
      color: COLORS.DOES_NOT_APPLY_GREY,
      label: t('views.lifemap.doesNotApply'),
    },
  } as const

  return (
    <Tooltip
      title={t('views.lifemap.createPriority')}
      open={displayTooltip}
      sx={{
        fontSize: 16,
        textAlign: 'center',
        bgcolor: theme => theme.palette.grey[500],
      }}
    >
      <Box
        sx={{
          width: diameter,
          height: diameter,
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          ...styles,
        }}
      >
        <Spring
          to={{ number: diameter }}
          config={{ tension: 150, friction: 40, precision: 1 }}
        >
          {springProps => (
            <>
              <Tooltip
                title={tooltipText ?? spherePropertiesByColor[color].label}
              >
                <Box
                  sx={{
                    top: '-5px',
                    right: '-5px',
                    borderRadius: '50%',
                    width: springProps.number,
                    height: springProps.number,
                    backgroundColor: spherePropertiesByColor[color].color,
                    border: '3px solid #FFFFFF',
                  }}
                />
              </Tooltip>

              <SpherePin
                variant={variant}
                animated={animated}
                priority={priority}
                achievement={achievement}
              />
            </>
          )}
        </Spring>
      </Box>
    </Tooltip>
  )
}

interface SpherePinProps {
  priority: boolean
  animated: boolean
  achievement: boolean
  variant: SphereVariant
}

function SpherePin({ variant, achievement, priority }: SpherePinProps) {
  const isSmall = variant === 'small'
  const isMedium = variant === 'medium'
  const isNormal = variant === 'normal'

  return (
    <>
      {isSmall && (achievement || priority) && (
        <Box
          sx={{
            boxSizing: 'border-box',
            border: '2px solid #FFFFFF',
            borderRadius: '50%',
            backgroundColor: '#77ABE9',
            width: 14,
            height: 14,
            position: 'absolute',
            top: -2,
            right: -2,
          }}
        />
      )}
      {isMedium && (achievement || priority) && (
        <Box sx={{ position: 'absolute', top: -5, right: -5 }}>
          <img
            alt="Icon Achievement/Priority"
            src={achievement ? iconAchievement : iconPriority}
            style={{
              width: 22,
              height: 22,
              border: '2px solid #FFFFFF',
              borderRadius: '50%',
              backgroundColor: '#fff',
            }}
          />
        </Box>
      )}
      {isNormal && (achievement || priority) && (
        <Box sx={{ position: 'absolute', top: -5, right: -5 }}>
          <img
            alt="Icon Achievement/Priority"
            src={achievement ? iconAchievement : iconPriority}
            style={{
              width: 27,
              height: 27,
              border: '2px solid #FFFFFF',
              borderRadius: '50%',
              backgroundColor: '#fff',
            }}
          />
        </Box>
      )}
    </>
  )
}
